import { Link } from "react-router-dom";
import React from "react";

const HeroText = (props) => {
  const { image, title, subtitle, link, linkName } = props.data || {};

  return (
    <div className="hero-text-holder-content">
      <div className="hero-content-main">
        <div className="hero-text-box">
          <h1>{title}</h1>
          {subtitle && <h2>{subtitle}</h2>}
          {linkName && (
            <h3>
              <Link to={link}>{linkName}</Link>
            </h3>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeroText;
