import React, { Fragment, useEffect, useState } from "react";

import ContactFooter from "../components/ContactFooter";
import EquipmentSection from "../components/EquipmentSection";
import Gallery from "../components/Gallery";
import Hero from "../components/Hero";
import InfoImageTextSection from "../components/InfoImageTextSection";
import Products from "../components/ProductsSection";
import { fetchCatalogList } from "../actions/global";
import i18next from "i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [activeLanguage, setActiveLanguage] = useState(i18next.language);
  const { dataCatalogList } = useSelector((state) => state.globalReducer);

  useEffect(() => {
    dispatch(fetchCatalogList(0, activeLanguage));
  }, [dispatch]);

  const heroData = {
    image: "hero-home.png",
    title: t("home.title"),
    subtitle: t("home.description"),
    link: "/contact",
    linkName: t("home.link"),
  };

  return (
    <Fragment>
      <div className="container">
        <div className="home-holder-content">
          <Hero data={heroData} />
          <Products
            dataCatalogList={dataCatalogList
              ?.filter((item) => item.id_category == 1)
              .slice(0, 8)}
          />
        </div>
      </div>
      <div className="container-fluid background-item-back-color">
        <div className="container">
          <InfoImageTextSection />
        </div>
      </div>
      <div className="container">
        <div className="home-holder-content">
          <EquipmentSection
            dataCatalogList={dataCatalogList
              ?.filter((item) => item.id_category == 2)
              .slice(0, 8)}
          />
          <Gallery />
          <ContactFooter />
        </div>
      </div>
    </Fragment>
  );
};

export default Home;
