import { FormControl, MenuItem, Select } from "@mui/material";
import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  fetchAddProductToCart,
  fetchProductsDetail,
  fetchRelatedProduct,
} from "../actions/global";

import ContactFooter from "../components/ContactFooter";
import NumberInput from "../components/Reusable/NumberInput";
import ReCAPTCHA from "react-google-recaptcha";
import RelatedProducts from "../components/RelatedProductsSection";
import { SlideshowLightbox } from "lightbox.js-react";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { formattedPrice } from "../utils";
import i18next from "i18next";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const ProductDetail = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const recaptchaRef = useRef();

  // const key = "6LeUftEoAAAAAGV2wD_uDEKaIrwHMZFw7sKDgkC9"; //localhost
  const key = "6Le7BJYqAAAAALM_373PjAu7CYNDr_-Gq9L85ia7"; //fuka.rs

  const dispatch = useDispatch();
  const [activeLanguage, setActiveLanguage] = useState(i18next.language);
  const { dataProductDetailList, dataCartInfo, dataRelatedProductsList } =
    useSelector((state) => state.globalReducer);

  const [sizeProduct, setSizeProduct] = useState({});
  const [grindLevel, setGrindLevel] = useState("Whole beans");
  const [grindingProduct, setGrindingProduct] = useState({});
  const [qtyValue, setQtyValue] = useState(1);

  useEffect(() => {
    dispatch(fetchProductsDetail(id, activeLanguage));
    dispatch(fetchRelatedProduct(id));
  }, [dispatch, id]);

  // Inicijalizacija reCAPTCHA
  useEffect(() => {
    if (recaptchaRef?.current) {
      recaptchaRef.current.execute();
    }
  }, []);

  const heroData = {
    title: dataProductDetailList.name,
  };

  console.log(id, "----id---1");
  useEffect(() => {
    // Funkcija koja se pokreće kada se promeni jezik
    const handleLanguageChange = (lng) => {
      console.log(id, "----id---2");
      dispatch(fetchProductsDetail(id, lng));
    };

    // Dodavanje event listener-a za promenu jezika
    i18next.on("languageChanged", handleLanguageChange);

    // Čišćenje event listener-a kada se komponenta unmount-uje
    return () => {
      i18next.off("languageChanged", handleLanguageChange);
    };
  }, [dispatch, id]);

  useEffect(() => {
    const selectedProductSize = dataProductDetailList?.category_price?.find(
      (item) => item.id_category == 1
    );
    const selectedProductGrinding = dataProductDetailList?.category_price?.find(
      (item) => item.id_category == 2
    );

    if (selectedProductSize) setSizeProduct(selectedProductSize);
    if (selectedProductGrinding) setGrindingProduct(selectedProductGrinding);
  }, [dataProductDetailList]);

  const handleAddToCart = async (event) => {
    event.preventDefault();

    // Pokreće reCAPTCHA za akciju "submit"
    const token = await recaptchaRef.current.executeAsync("submit");

    if (!token) {
      toast.error("Problem sa verifikacijom, osvezite stranicu.");
      return;
    }

    const cartItemDetail = {
      id_product: dataProductDetailList.id,
      price:
        dataProductDetailList.id_category == 1
          ? sizeProduct.price
          : dataProductDetailList.price,
      qty: qtyValue,
      grinding:
        dataProductDetailList?.id_category == 2
          ? ""
          : grindingProduct?.name || "",
      size:
        dataProductDetailList?.id_category == 2 ? "" : sizeProduct?.name || "",
      level: dataProductDetailList?.id_category == 2 ? "" : grindLevel || "",
      token: token,
      id_category_id: sizeProduct?.id_category_id || 0,
      id_order: dataCartInfo?.id ? dataCartInfo.id : 0,
    };

    dispatch(fetchAddProductToCart(cartItemDetail, t));
  };

  const handleChangeQty = (event, value) => {
    event.preventDefault();
    setQtyValue(value);
  };

  return (
    <div className="product-detail-holder-content">
      <div className="container">
        {/* <div className="product-detail-hero-holder">
          <Hero data={heroData} />
        </div> */}

        <form onSubmit={handleAddToCart}>
          <div className="product-detail-content-main">
            <div className="product-detail-information">
              <div className="product-detail-image-holder">
                {dataProductDetailList?.image && (
                  <SlideshowLightbox
                    className="container grid grid-cols-3 gap-2 mx-auto"
                    showThumbnails={true}
                    key={dataProductDetailList?.id}
                  >
                    <img
                      key={dataProductDetailList?.id}
                      src={dataProductDetailList?.image}
                      className="img-fluid"
                      alt="Fuka"
                    />
                  </SlideshowLightbox>
                )}
              </div>
              <div className="product-detail-text-holder">
                <h1>{dataProductDetailList.name}</h1>
                {/* <div className="product-detail-category-status-holder">
                  <div className="product-detail-category">
                    {dataProductDetailList?.id_category == 1
                      ? t("menu.coffee")
                      : t("menu.equipment")}
                  </div>
                  <div className="product-detail-status">
                    Status: <span>{t("other.status_product")}</span>
                  </div>
                </div> */}
                <div className="product-detail-short-description">
                  <h5
                    dangerouslySetInnerHTML={{
                      __html: dataProductDetailList.description,
                    }}
                  ></h5>
                </div>
                {dataProductDetailList?.category_price?.some(
                  (item) => item.id_category == 1
                ) && (
                  <div className="product-detail-size-holder">
                    <div className="product-detail-section-title">
                      {t("cart_view.size")}
                    </div>
                    <div className="product-detail-size-items">
                      {dataProductDetailList.category_price
                        .filter((item) => item.id_category == 1)
                        .map((item) => (
                          <div
                            key={item.id}
                            className={`product-detail-size-item ${
                              item.id == sizeProduct.id ? "active" : ""
                            }`}
                            onClick={() => setSizeProduct(item)}
                          >
                            {item.name}
                          </div>
                        ))}
                    </div>
                  </div>
                )}

                {dataProductDetailList?.category_price?.some(
                  (item) => item.id_category == 2
                ) && (
                  <Fragment>
                    <div className="product-detail-grinding-holder">
                      <div className="product-detail-section-title">
                        {t("cart_view.roast")}
                      </div>
                      <div className="product-detail-grinding-items">
                        {dataProductDetailList.category_price
                          ?.filter((item) => item.id_category == 2)
                          .map((item) => (
                            <div
                              key={item.id} // Dodaj jedinstveni ključ
                              className={`product-detail-grinding-item ${
                                item.id == grindingProduct?.id ? "active" : ""
                              }`}
                              onClick={() => setGrindingProduct(item)}
                            >
                              {item.name}
                            </div>
                          ))}
                      </div>
                    </div>
                    <div className="product-detail-grinding-holder">
                      <div className="product-detail-section-title">
                        {t("cart_view.level")}
                      </div>
                      <div className="product-detail-grinding-items">
                        <FormControl fullWidth size="small" required>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={grindLevel}
                            onChange={(event) =>
                              setGrindLevel(event.target.value)
                            }
                            size="small"
                          >
                            <MenuItem value="Whole beans">Whole beans</MenuItem>
                            <MenuItem value="Espresso Machine">
                              Espresso Machine
                            </MenuItem>
                            <MenuItem value="Moka Pot">Moka Pot</MenuItem>
                            <MenuItem value="Aeropress">Aeropress</MenuItem>
                            <MenuItem value="V60">V60</MenuItem>
                            <MenuItem value="French Press">
                              French Press
                            </MenuItem>
                            <MenuItem value="Chemex">Chemex</MenuItem>
                            <MenuItem value="Automatic Filter Machine">
                              Automatic Filter Machine
                            </MenuItem>
                            <MenuItem value="Turkish">Turkish</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </Fragment>
                )}

                <div className="product-detail-price-holder">
                  <div className="product-detail-section-title">
                    {t("cart_view.table_price")}
                  </div>
                  <div className="product-detail-price-item">
                    <div className="product-detail-price">
                      {dataProductDetailList &&
                      dataProductDetailList.id_category == 1
                        ? formattedPrice(sizeProduct?.price)
                        : formattedPrice(dataProductDetailList?.price)}{" "}
                      RSD
                    </div>
                  </div>
                </div>
                <div className="product-detail-quantity-holder">
                  <div className="product-detail-quantity-content">
                    <div className="product-detail-quantity-input">
                      <NumberInput handleChangeQty={handleChangeQty} />
                    </div>
                    <div className="product-detail-quantity-button">
                      <button className="add-to-cart-button">
                        {t("other.add_to_cart")}
                      </button>
                    </div>
                  </div>
                  <p>* {t("other.free_delivery")}</p>
                </div>
              </div>
            </div>
            {dataProductDetailList?.long_description && (
              <div className="product-detail-more">
                <div className="product-detail-more-list">
                  <div className="prodcut-detail-more-item active">
                    {t("other.productDesc")}
                  </div>
                  {/* <div className="prodcut-detail-more-item">
              Additional Information
            </div> */}
                </div>
                <div className="product-detail-more-body">
                  {dataProductDetailList?.long_description}
                </div>
              </div>
            )}
          </div>
          <ReCAPTCHA sitekey={key} size="invisible" ref={recaptchaRef} />
        </form>

        <RelatedProducts items={dataRelatedProductsList} />
        <ContactFooter />
      </div>
    </div>
  );
};

export default ProductDetail;
