import ContactFooter from "../components/ContactFooter";
import HeroText from "../components/HeroText";
import React from "react";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  const heroData = {
    title: t("policy_page.title"),
  };
  return (
    <div className="terms-holder-content">
      <div className="container">
        <div className="terms-hero-holder">
          <HeroText data={heroData} />
        </div>
        <div className="terms-content-main">
          <div
            className=""
            dangerouslySetInnerHTML={{ __html: t("policy_page.body") }}
          />
          {/* <p>
            ФУКА poštuje privatnost posetilaca svoje veb stranice i posvećena je
            zaštiti njihovih ličnih podataka. Ova Politika privatnosti
            objašnjava kako prikupljamo, koristimo i štitimo vaše podatke, kao i
            vaša prava u vezi sa njima. Nastavkom korišćenja naše veb stranice
            pristajete na ovde navedene uslove, uključujući upotrebu kolačića i
            tehnologija za praćenje.
          </p>
          <h2>Član 1: Definicije</h2>
          <p>
            Veb stranica – Odnosi se na fuka.rs. Rukovalac podacima – Entitet
            odgovoran za obradu ličnih podataka: Fuka d.o.o., Masarikov trg 17,
            11080 Beograd, kontakt e-mail: info@fuka.rs.
          </p>
          <h2>Član 2: Pristup veb stranici</h2>
          <p>
            Pristup i korišćenje ove veb stranice je isključivo lične prirode.
            Saglasni ste da ne koristite njen sadržaj u komercijalne, političke
            ili reklamne svrhe, niti za bilo kakve neovlašćene komercijalne
            ponude.
          </p>
          <h2>Član 3: Sadržaj veb stranice</h2>
          <p>
            Sav sadržaj na ovoj veb stranici, uključujući logotipe, slike,
            tekstove i tehničke aplikacije, zaštićen je zakonima o
            intelektualnoj svojini. Zabranjeno je bilo kakvo neovlašćeno
            kopiranje, korišćenje ili modifikacija.
          </p>
          <h2>Član 4: Upravljanje veb stranicom</h2>
          <p>Kako bi održala optimalnu funkcionalnost, fuka.rs može:</p>
          <ul>
            <li>
              Ograničiti ili suspendovati pristup određenim funkcijama sajta.
            </li>
            <li>
              Ukloniti sadržaj koji ometa rad veb stranice ili krši propise.
            </li>
            <li>
              Privremeno deaktivirati veb stranicu radi održavanja i ažuriranja.
            </li>
          </ul>
          <h2>Član 5: Odgovornosti</h2>
          <ul>
            <li>
              Fuka.rs nije odgovorna za nedostupnost sajta, tehničke probleme
              ili prekide uzrokovane trećim licima. Korisnici su dužni da
              preduzmu odgovarajuće mere zaštite svojih uređaja i ličnih
              podataka.
            </li>
            <li>
              Ne preuzimamo odgovornost za pravne postupke protiv korisnika zbog
              nepravilnog korišćenja veb stranice. Korisnici snose odgovornost
              za svoje aktivnosti na internetu, uključujući i posete eksternim
              sajtovima povezanim sa našom platformom.
            </li>
          </ul>
          <h2>Član 6: Prikupljanje ličnih podataka</h2>
          <ul>
            <li>
              Fuka.rs prikuplja lične podatke radi održavanja odnosa sa kupcima
              i obrade porudžbina.
            </li>
            <li>
              Lični podaci uključuju identifikatore kao što su imena, e-mail
              adrese, IP adrese i druge informacije koje mogu identifikovati
              pojedinca.
            </li>
            <li>Ovi podaci se bezbedno čuvaju u elektronskoj bazi podataka.</li>
          </ul>
          <h2>Član 7: Vaša prava u vezi sa ličnim podacima</h2>
          <p>
            U skladu sa Opštom uredbom o zaštiti podataka (GDPR), imate pravo
            da:
          </p>
          <ul>
            <li>Pristupite, ispravite ili obrišete svoje lične podatke.</li>
            <li>Ograničite ili se usprotivite njihovoj obradi.</li>
            <li>Zatražite prenosivost podataka.</li>
          </ul>
          <p>
            Za ostvarivanje ovih prava, kontaktirajte nas na info@fuka.rs.
            Zahtevi moraju uključivati dokaz identiteta (npr. potpisanu kopiju
            lične karte). Odgovorićemo u roku od mesec dana, a ako je zahtev
            složen, rok može biti produžen na dva meseca.
          </p>
          <h2>Član 8: Zakonske obaveze</h2>
          <p>
            U slučaju zahteva državnih organa ili po zakonskoj obavezi, Fuka
            d.o.o. može otkriti lične podatke. Nakon takvog deljenja, ti podaci
            više ne podležu ovoj Politici privatnosti.
          </p>
          <h2>Član 9: Marketing i komercijalna komunikacija</h2>
          <p>
            Možete primati promotivne e-mailove od fuka.rs. Ako želite da
            otkažete prijem, kontaktirajte nas na info@fuka.rs.
          </p>
          <p>
            Ne delimo vaše lične podatke sa trećim licima u komercijalne svrhe.
            Ukoliko naiđete na lične podatke drugih korisnika na našoj veb
            stranici, zabranjeno je njihovo prikupljanje ili korišćenje bez
            dozvole.
          </p>
          <h2>Član 10: Čuvanje podataka</h2>
          <p>
            Lične podatke čuvamo samo onoliko dugo koliko je potrebno, u skladu
            sa zakonskim regulativama.
          </p>
          <h2>Član 11: Kolačići i tehnologije praćenja</h2>
          <p>
            ФУКА koristi kolačiće radi poboljšanja performansi veb stranice i
            korisničkog iskustva. Nastavkom pretraživanja, pristajete na njihovu
            upotrebu osim ako ih ne onemogućite u podešavanjima pregledača.
          </p>
          <p>Koristimo:</p>
          <ul>
            <li>Funkcionalne kolačiće (za prijavu i praćenje sesija).</li>
            <li>
              Anonimne analitičke kolačiće (za analizu saobraćaja na sajtu).
            </li>
          </ul>
          <p>Ne koristimo dodatne kolačiće izvan ovih osnovnih tipova.</p>

          <h2>Član 12: Fotografije proizvoda</h2>
          <p>
            Fotografije proizvoda na veb stranici služe isključivo kao
            referenca. Ne može se iz njih proizvesti nikakvo pravno pravo.
          </p>
          <h2>Član 13: Merodavno pravo</h2>
          <p>
            Ova Politika privatnosti podleže zakonima Republike Srbije. Svi
            sporovi će se rešavati pred sudom nadležnim za sedište Fuka d.o.o.,
            osim ako zakonski propisi nalažu drugačije.
          </p>
          <h2>Član 14: Kontakt</h2>
          <p>
            Za sva pitanja u vezi sa Politikom privatnosti ili funkcionalnošću
            veb stranice, kontaktirajte nas na: info@fuka.rs.
          </p> */}
        </div>{" "}
        <ContactFooter />
      </div>
    </div>
  );
};

export default PrivacyPolicy;
