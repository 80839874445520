import React, { useState } from "react";

import BackToTop from "../Reusable/BackToTop";
import Footer from "../Layout/Footer";
import { Fragment } from "react";
import Navigation from "../Layout/Navigation";
import { useTranslation } from "react-i18next";

const ThemeWrapper = (props) => {
  const { t } = useTranslation();
  const [showBlock, setShowBlock] = useState(false);

  return (
    <Fragment>
      <Navigation />
      <main className="main main-site-container-shop">
        {/* <div className="container">{props.children}</div> */}
        {props.children}
      </main>
      <Footer />
    </Fragment>
  );
};

export default ThemeWrapper;
