import ContactFooter from "../components/ContactFooter";
import Hero from "../components/Hero";
import React from "react";
import { useTranslation } from "react-i18next";

const ErrorPage = () => {
  const { t } = useTranslation();
  const heroData = {
    image: "error.png",
    title: t("error_page.title"),
    subtitle: t("error_page.subtitle"),
    link: "/",
    linkName: t("error_page.linkName"),
  };

  return (
    <div className="home-holder-content">
      <div className="container">
        <div className="error-holder-content">
          <Hero data={heroData} />
        </div>
        <ContactFooter />
      </div>
    </div>
  );
};

export default ErrorPage;
