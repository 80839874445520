import { Link } from "react-router-dom";
import React from "react";
import { formattedPrice } from "../utils";

const CheckoutProductItem = ({ item }) => {
  return (
    <div className="checkout-information-list-item">
      <div className="checkout-information-list-item-image">
        <Link to={`/product/${item?.id_product}`}>
          <div className="checkout-information-list-item-qty">{item?.qty}</div>
          <img src={item?.image} alt="Fuka" />
        </Link>
      </div>
      <div className="checkout-information-list-item-text">
        <Link to={`/product/${item?.id_product}`}>
          <h5>{item?.name}</h5>
          {item?.size && (
            <div className="checkout-information-list-item-size">
              Size: <b>{item?.size}</b>
            </div>
          )}
          <div className="checkout-information-list-item-size">
            Grind level: <b>{item?.level}</b>
          </div>
          <div className="checkout-information-list-item-price-grinding">
            {item?.grinding && (
              <div className="checkout-information-list-item-grinding">
                Roast: <b>{item?.grinding}</b>
              </div>
            )}
            <div className="checkout-information-list-item-price">
              {formattedPrice(item?.price * item?.qty)} <small>RSD</small>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default CheckoutProductItem;
