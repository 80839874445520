import React, { useEffect, useState } from "react";

import ContactFooter from "../components/ContactFooter";
import Hero from "../components/Hero";
import ProductItem from "../components/ProductItem";
import { fetchCatalogList } from "../actions/global";
import i18next from "i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const CatalogCoffee = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [activeLanguage, setActiveLanguage] = useState(i18next.language);
  const { dataCatalogList } = useSelector((state) => state.globalReducer);

  useEffect(() => {
    dispatch(fetchCatalogList(1, activeLanguage));
  }, [dispatch]);

  const heroData = {
    image: "hero-coffee.png",
    title: t("pages.coffee_title"),
    subtitle: t("pages.coffee_subtitle"),
  };

  useEffect(() => {
    // Funkcija koja se pokreće kada se promeni jezik
    const handleLanguageChange = (lng) => {
      dispatch(fetchCatalogList(1, lng));
    };

    // Dodavanje event listener-a za promenu jezika
    i18next.on("languageChanged", handleLanguageChange);

    // Čišćenje event listener-a kada se komponenta unmount-uje
    return () => {
      i18next.off("languageChanged", handleLanguageChange);
    };
  }, [dispatch]);

  return (
    <div className="catalog-holder-content">
      <div className="container">
        <Hero data={heroData} />
        {/* <div className="products-catalog-filter-holder">
          <div className="showing-results">
            {t("other.showing_results")} {dataCatalogList.length}
          </div>
        </div> */}
        <div className="products-content-main">
          {dataCatalogList?.map((item, key) => (
            <ProductItem item={item} key={key} />
          ))}
        </div>
        <ContactFooter />
      </div>
    </div>
  );
};

export default CatalogCoffee;
