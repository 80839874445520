import { Link, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";

import CartPageItem from "../components/CartPageItem";
import ContactFooter from "../components/ContactFooter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HeroText from "../components/HeroText";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { fetchGetItemsFromCart } from "../actions/global";
import { formattedPrice } from "../utils";
import i18next from "i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Cart = () => {
  const { t } = useTranslation();
  const heroData = {
    title: t("pages.cart_title"),
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { dataCartInfo, dataCartItemsInfo } = useSelector(
    (state) => state.globalReducer
  );

  useEffect(() => {
    if (dataCartInfo?.total_order_items == 0) {
      navigate("/");
    }
  }, [dataCartInfo, navigate]);

  useEffect(() => {
    if (dataCartInfo.id) {
      dispatch(fetchGetItemsFromCart(dataCartInfo.id, i18next.language));
    }
  }, []);

  return (
    <div className="cart-holder-content">
      <div className="container">
        <div className="cart-hero-holder">
          <HeroText data={heroData} />
        </div>
        <div className="cart-holder-main-content">
          <div className="cart-content-main">
            <div className="cart-content-table-holder">
              <table className="table">
                <thead>
                  <tr>
                    <th>{t("cart_view.table_product")}</th>
                    <th className="text-end">{t("cart_view.level")}</th>
                    <th className="text-end">{t("cart_view.size")}</th>
                    <th className="text-end">{t("cart_view.roast")}</th>
                    <th className="text-end">{t("cart_view.table_price")}</th>
                    <th className="text-end">{t("cart_view.table_qty")}</th>
                    <th className="text-end">
                      {t("cart_view.table_subtotal")}
                    </th>
                    <th className="text-end">{t("cart_view.table_remove")}</th>
                  </tr>
                </thead>
                <tbody>
                  {dataCartItemsInfo?.map((item, key) => (
                    <CartPageItem item={item} key={key} />
                  ))}
                </tbody>
              </table>
            </div>
            <div className="cart-content-total-price-holder">
              <div className="cart-content-total-left-side">
                <div className="cart-content-continue-shopping">
                  <Link to="/home">
                    <FontAwesomeIcon icon={faArrowLeft} />{" "}
                    {t("cart_view.continue_shopping")}
                  </Link>
                </div>
              </div>
              <div className="cart-content-total-right-side">
                {/* <div className="cart-content-total-title">
                  {t("cart_view.cart_total")}
                </div> */}
                <div className="cart-content-total-price-conetnt">
                  <div className="cart-content-total-subtotal d-flex justify-content-between gap-5 mb-2">
                    <div className="cart-content-total-subtotal-title">
                      {t("cart_view.cart_subtotal")}
                    </div>
                    <div className="cart-content-total-subtotal-price">
                      {formattedPrice(dataCartInfo?.total_price_sum)} RSD
                    </div>
                  </div>
                  <div className="cart-content-total-shipping d-flex justify-content-between gap-5 mb-2">
                    <div className="cart-content-total-shipping-title">
                      {t("cart_view.cart_shipping")}
                    </div>
                    <div className="cart-content-total-shipping-price">
                      {formattedPrice(dataCartInfo?.shipping)} RSD
                    </div>
                  </div>
                </div>
                <div className="cart-content-total-total d-flex justify-content-between gap-5 mb-4">
                  <div className="cart-content-total-total-title">
                    {t("cart_view.cart_total_total")}
                  </div>
                  <div className="cart-content-total-total-price">
                    {formattedPrice(
                      +dataCartInfo?.total_price_sum + +dataCartInfo?.shipping
                    )}{" "}
                    RSD
                  </div>
                </div>
                <div className="cart-content-total-button-holder">
                  <Link to="/checkout">
                    <button className="button-custom-black w-100">
                      {t("cart_view.checkout_button_proceed")}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ContactFooter />
      </div>
    </div>
  );
};

export default Cart;
