import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { fetchRemoveItemFromCart } from "../actions/global";
import { formattedPrice } from "../utils";
import i18next from "i18next";
import { useDispatch } from "react-redux";

const CartModalItem = ({ item }) => {
  const dispatch = useDispatch();

  const removeItemFromCart = (item) => {
    dispatch(fetchRemoveItemFromCart(item.id_order, item.id, i18next.language));
  };
  console.log(item, "v");

  return (
    <div className="cart-content-item">
      <div className="cart-content-item-image">
        <img src={item?.image} alt="Fuka" className="img-fluid" />
        <div className="checkout-information-list-item-qty">{item?.qty}</div>
      </div>
      <div className="cart-content-item-text">
        <h5>{item?.name}</h5>
        <div className="cart-content-other-info">
          <h6>
            {item?.size} / {item?.grinding} / {item?.level}
          </h6>
        </div>
        <div className="cart-content-item-price">
          <span>{formattedPrice(item?.qty * item?.price)}</span>{" "}
          <small>RSD</small>
        </div>
      </div>
      <div
        className="cart-content-item-remove"
        onClick={() => removeItemFromCart(item)}
      >
        <FontAwesomeIcon icon={faClose} />
      </div>
    </div>
  );
};

export default CartModalItem;
