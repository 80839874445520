import ContactFooter from "../components/ContactFooter";
import HeroText from "../components/HeroText";
import React from "react";
import { useTranslation } from "react-i18next";

const DeliveryPage = () => {
  const { t } = useTranslation();
  const heroData = {
    title: t("delivery_page.title"),
  };

  return (
    <div className="terms-holder-content">
      <div className="container">
        <div className="terms-hero-holder">
          <HeroText data={heroData} />
        </div>
        <div className="terms-content-main">
          <div dangerouslySetInnerHTML={{ __html: t("delivery_page.body") }} />
        </div>
        <ContactFooter />
      </div>
    </div>
  );
};

export default DeliveryPage;
