import React from "react";
import { useTranslation } from "react-i18next";

const InfoImageTextSection = () => {
  const { t } = useTranslation();
  return (
    <div className="info-image-text-section-holder">
      <div className="info-image-text-section-content">
        <div className="info-image-text-section-image">
          <img src="/images/gallery-3.png" className="img-fluid" alt="Fuka" />
        </div>
        <div className="info-image-text-section-text">
          <div className="info-image-text-section-text-holder">
            <h4>{t("other.section_3_title")}</h4>
            <p>{t("other.section_3_desc")} </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoImageTextSection;
