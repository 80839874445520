import AboutInfoSection from "../components/AboutInfoSection";
import ContactFooter from "../components/ContactFooter";
import Hero from "../components/Hero";
import ImageText from "../components/ImageText";
import React from "react";
import TextImage from "../components/TextImage";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();

  const heroData = {
    image: "hero-about.png",
    title: t("about.title"),
    subtitle: t("about.subtitle"),
    // link: "/catalog/coffee",
    // linkName: t("about.linkText"),
  };

  return (
    <div className="about-holder-content">
      <div className="container">
        <Hero data={heroData} />
        <ImageText
          image={"/images/hero-home.png"}
          title={t("about.section_1.title")}
          description={t("about.section_1.description")}
        />
        <TextImage
          image={"/images/our1.png"}
          title={t("about.section_2.title")}
          description={t("about.section_2.description")}
        />
        <ImageText
          image={"/images/server1.png"}
          title={t("about.section_55.title")}
          description={t("about.section_55.description")}
        />
      </div>
      <div className="container-fluid p-0">
        <div className="about-backgorund-color">
          <div className="container">
            <AboutInfoSection t={t} />
          </div>
        </div>
      </div>
      <div className="container">
        <ContactFooter />
      </div>
    </div>
  );
};

export default About;
