import React, { Fragment, useState } from "react";

import InputField from "./Reusable/InputField";
import { Link } from "react-router-dom";
import { fetchAddNewsletter } from "../actions/global";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const Hero = (props) => {
  const { image, title, subtitle, link, linkName, input } = props.data || {};

  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const resetValue = () => {
    setValue("");
  };

  const submitForm = () => {
    if (!value) {
      toast.error("Molimo unesite e-mail adresu.", { theme: "colored" });
      return;
    }
    if (!validateEmail(value)) {
      toast.error("Unesena e-mail adresa nije validna.", { theme: "colored" });
      return;
    }

    const valueAttr = {
      acEmail: value,
    };

    dispatch(fetchAddNewsletter(valueAttr, resetValue));
  };

  return (
    <div className="hero-holder-content">
      <div className="hero-content-main">
        <div className="hero-text-box">
          <h1>{title}</h1>
          {subtitle && <h2 dangerouslySetInnerHTML={{ __html: subtitle }} />}
          {linkName && (
            <h3>
              <Link to={link}>{linkName}</Link>
            </h3>
          )}
          {input && (
            <div className="footer-newsletter-holder">
              <InputField
                id="outlined-basic"
                className="w-100"
                placeholder={t("other.newsletter_input")}
                name="email"
                type="email"
                size="small"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
              <button className="button-custom-black" onClick={submitForm}>
                {t("other.newsletter_button")}
              </button>
            </div>
          )}
        </div>
        <div className="hero-image-box">
          {image && (
            <img src={"/images/" + image} className="img-fluid" alt="Fuka" />
          )}
        </div>
      </div>
    </div>
  );
};

export default Hero;
