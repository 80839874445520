import { Link } from "react-router-dom";
import React from "react";
import { formattedPrice } from "../utils";
import { useTranslation } from "react-i18next";

const ProductItem = (props) => {
  const { image, id_category, id_product, name, price, cena_proizvoda } =
    props.item || {};
  const { t } = useTranslation();

  return (
    <Link to={"/product/" + id_product}>
      <div className="products-item">
        <div className="products-item-holder">
          <div className="products-item-image">
            <img src={image} className="img-fluid" alt="Fuka" />
          </div>
          <div className="products-item-text">
            {/* <h4>{id_category == 1 ? t("menu.coffee") : t("menu.equipment")}</h4> */}
            <h3>{name}</h3>
            {id_category == 1 ? (
              <p>
                {t("other.from")} {formattedPrice(cena_proizvoda)}
                <small>RSD</small>
              </p>
            ) : (
              <p>
                {formattedPrice(price)}
                <small>RSD</small>
              </p>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ProductItem;
