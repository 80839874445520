import "react-image-lightbox/style.css"; // Ne zaboravi da importuješ stilove

import React, { useState } from "react";

import Lightbox from "react-image-lightbox";

const GalleryImages = () => {
  const images = [
    "/images/gallery1.jpg",
    "/images/gallery2.jpg",
    "/images/gallery3.jpg",
    "/images/gallery4.jpg",
    "/images/gallery5.jpg",
    "/images/gallery9.jpg",
    "/images/gallery7.jpg",
    "/images/gallery6.jpg",
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openLightbox = (index) => {
    setCurrentImageIndex(index);
    setIsOpen(true);
  };

  return (
    <div className="gallery-holder-content">
      <div className="gallery-content-main">
        {images.map((image, index) => (
          <div key={index} className="gallery-item">
            <img
              src={image}
              className="img-fluid"
              alt={`Fuka ${index}`}
              onClick={() => openLightbox(index)}
            />
          </div>
        ))}
      </div>

      {/* Lightbox */}
      {isOpen && (
        <Lightbox
          mainSrc={images[currentImageIndex]}
          nextSrc={images[(currentImageIndex + 1) % images.length]}
          prevSrc={
            images[(currentImageIndex + images.length - 1) % images.length]
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setCurrentImageIndex(
              (currentImageIndex + images.length - 1) % images.length
            )
          }
          onMoveNextRequest={() =>
            setCurrentImageIndex((currentImageIndex + 1) % images.length)
          }
        />
      )}
    </div>
  );
};

export default GalleryImages;
